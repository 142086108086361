<template>
  <div
    v-loading="loading"
    class="bordered"
  >
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :md="6"
      >
        <div class="form-control">
          <label>
            Audience Name
          </label>
          <el-input
            v-model="name"
          />
        </div>
        <div class="form-control">
          <label>
            Organization
          </label>
          <org-picker :organization.sync="organization" />
        </div>
        <div class="form-control">
          <label>
            Tags
          </label>
          <tag-picker :tags.sync="tags" />
        </div>
        <div>
          <el-button
            :disabled="cannotUpload"
            type="primary"
            @click="handlePlaceRequest"
          >
            Place Request
          </el-button>
        </div>
      </el-col>
      <el-col
        style="position: relative"
        :xs="12"
        :md="6"
      >
        <uploader
          ref="uploader"
          :accept-file-exts="fileExts"
          api-endpoint="/api/v1/audiences/import/carrier-routes"
          single-file
          upload-field-name="imports"
          @complete="uploadComplete"
          @file-change="handleFileChange"
          @upload-state-change="handleStateChange"
        />
      </el-col>
      <el-col
        :xs="24"
        :md="12"
        class="help-text"
      >
        <p>Please provide a single column .csv file containing carrier routes, one per line.</p>
        <p><strong>Guidelines:</strong> A valid organization must be selected.<br>Carrier routes must be in the format of 5-digit zipcode, followed by letter for the route type and 3-digit route number. For example, 90210R002.<br>The file is assumed to <em>not</em> have a header row.</p>
      </el-col>
    </el-row>
    <csv-problems
      :headers="['route']"
      :problems="problems"
      :visible="showProblems"
      @close="() => showProblems = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isUTF8 } from '../../helpers';
import { getSignedS3Url } from '../../methods/aws';
import CsvProblems from '../global/CsvProblems';
import OrgPicker from '../global/OrgPicker';
import Uploader from '../global/Uploader.vue';
import TagPicker from '../global/TagPicker';
import papa from 'papaparse';

const ADDRESS_LIMIT = 5000;

export default {
  components: {
    CsvProblems,
    OrgPicker,
    TagPicker,
    Uploader,
  },

  data() {
    return {
      loading: false,
      errors: [],
      fileList: [],
      fileExts: ['.csv'],
      name: '',
      organization: this.$store.state.user.orgDetails,
      problems: [],
      showProblems: false,
      tags: [],
    };
  },

  computed: {
    ...mapGetters('settings', [
      'reverseAppendsTemplateUri',
    ]),
    cannotUpload() {
      return this.name === '' || this.fileList.length === 0;
    },
  },

  methods: {
    downloadTemplate() {
      getSignedS3Url
        .callPromise({
          uri: this.$store.state.settings.reverseAppendsTemplateUri,
        })
        .then(url => {
          window.location.href = url;
        })
        .catch(err => this.$reportError(err));
    },

    async handleFileChange(fileList) {
      this.loading = true;
      const utf8 = await isUTF8(fileList[0]);
      if (!utf8) {
        this.$refs.uploader.reset();
        this.$notify.error({
          message: 'File is not UTF-8 encoded.',
        });
        this.loading = false;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const body = reader.result;
        const firstLine = body.split('\n').shift();
        const header = firstLine.split(',');

        if (header.length === 1) {
          this.fileList = fileList;
        } else {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'File does not match required format.',
          });
        }
      };
      this.problems = [];
      papa.parse(fileList[0], {
        header: false,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: async (results, file) => {
          results.data.map((r, idx) => {
            if (!/\d{5}[bchrBCHR]\d{3}/.test(r[0])) {
              if (idx > 0) {
                this.problems.push({ route: r[0], field: 'route' });
              }
            }
          });

          if (this.problems.length > 0) {
            this.showProblems = true;
          }

          if (results.data.length > ADDRESS_LIMIT) {
            this.$notify.error({
              message: `This file is too large, please limit the number of carrier routes to ${ADDRESS_LIMIT.toLocaleString()} or fewer.`,
            });
            this.$refs.uploader.reset();
            this.fileList = [];
          }
          this.loading = false;
        },
      });

      reader.readAsText(fileList[0], 'UTF-8');
    },

    handleStateChange(isUploading) {
      this.loading = isUploading;
    },

    async handlePlaceRequest() {
      this.loading = true;
      try {
        if (this.fileList.length > 0) {
          await this.$refs.uploader.startUpload({
            name: this.name,
            organization_id: this.organization.id,
            tags: JSON.stringify(this.tags),
          });
          window.mixpanel.track('Carrier Routes', {
            Name: this.name,
            Organization: this.organization.name,
            Tags: this.tags,
          });
        }
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message: e.error,
          duration: 7500,
        });
      }

      this.resetForm();
    },

    resetForm() {
      this.$refs.uploader.reset();
      this.loading = false;
      this.organization = this.$store.state.user.orgDetails;
      this.name = '';
      this.tags = [];
      this.files = [];
    },

    uploadComplete(result) {
      if (!result.status === 'success') {
        return this.$notify.error({
          message: 'Your request was not able to be processed.',
        });
      }

      this.$store.dispatch('mqtt/addRawJob', result.data.job);

      this.$notify.success({
        message: `Processing ${result.data.addressCount} addresses.`,
      });
    },
  },
};
</script>

<style lang="scss">
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
  .el-select {
    width: 60%;
  }
}
.el-upload {
  width: 100% !important;
  .el-upload-dragger {
    width: 100% !important;
  }
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.help-text p {
  font-size: 0.85rem;
  pre {
    font-size: 1rem;
    background: #ddd;
    padding: 5px;
    border: 1px solid #666;
  }
}
</style>
